import React from "react"
import Chart from 'chart.js'

class DashboardChart extends React.Component {

    chartColors = {
        red: 'rgb(255, 99, 132)',
        // orange: 'rgb(255, 159, 64)',
        // yellow: 'rgb(255, 205, 86)',
        green: '#00E7AF',
        //blue: 'rgb(54, 162, 235)',
        //purple: 'rgb(153, 102, 255)',
        //grey: 'rgb(201, 203, 207)'
    };
    chart = null;
    chartCoordinates = null;
    xLabels = null;
    componentDidMount(){
        this.chartCoordinates = this.props.chartCoordinates;
        this.xLabels = this.props.xLabels;
        this.initChartWithData();

    }

    componentWillUpdate(nextProps){
        if(JSON.stringify(this.chartCoordinates) !== JSON.stringify(nextProps.chartCoordinates)){
            this.chartCoordinates = nextProps.chartCoordinates;
            this.xLabels = nextProps.xLabels;
            this.initChartWithData();
        }
    }

    initChartWithData(){
        let config = {
            type: 'line',
            data: {
                labels: this.xLabels,
                datasets: [
                    {
                        label: this.props.label,
                        // fill: false,
                        backgroundColor: '#FFF',
                        borderColor: this.chartColors.green,
                        borderWidth: 2,
                        data:this.chartCoordinates.past,
                    },
                    {
                        label: "Projection",
                        // fill: false,
                        backgroundColor: '#FFF',
                        borderColor: this.chartColors.green,
                        borderWidth: 2,
                        data:this.chartCoordinates.future,
                        borderDash: [10,5]
                    },
                ]
            },
            options: {
                layout: {
                    padding: {
                        left: 30,
                        right: 20,
                        top: 0,
                        bottom: 30
                    }
                },
                responsive: true,
                title: {
                    display: true,
                    text: 'Business Score'
                },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                hover: {
                    mode: 'nearest',
                    intersect: true
                },
                scales: {
                    xAxes: [{
                        display: true,
                        scaleLabel: {
                            display: false,
                            labelString: 'Month'
                        }
                    }],
                    yAxes: [{
                        display: true,
                        scaleLabel: {
                            display: false,
                            labelString: 'Value'
                        },
                        ticks:{
                          suggestedMax:940,
                          suggestedMin:0
                        }
                    }]
                },
                legend:{
                    align: 'end',
                }
            }
        };

        let ctx = document.getElementById('canvas').getContext('2d');
        this.chart = new Chart(ctx, config);
    }

    render(){
        return (
            <div className="canvasWrap">
                <canvas id="canvas"></canvas>
            </div>
        )
    }

}

export default DashboardChart
