import React, { useState, useContext, useEffect } from "react"
import { navigate } from "gatsby"
import axios from 'axios'
import moment from 'moment'
import Layout from "../components/layout"
import DashboardChart from "../components/DashboardChart"
import { AppStateContext } from "../contexts/AppStateContext"
// import AnimatedMeter from "../components/AnimatedMeter"
import settings from "../lib/settings"
import FundBox from "../components/FundBox"


let axiosInstance = null;

const DashboardMainPage = () => {

    const appState = useContext(AppStateContext);
    const [loading, setLoading] = useState(true)
    const [businessName, setBusinessName] = useState("");
    const [businessAddress, setBusinessAddress] = useState("");
    const [asOfDate, setAsOfDate] = useState("");
    const [score, setScore] = useState("");
    const [rating, setRating] = useState("");
    const [chartLabel, setChartLabel] = useState("");
    const [chartCoordinates, setChartCoordinates] = useState({past: [],future: []});
    const [chartXLabels, setChartXLabels] = useState([]);
    const [monthLabels, setMonthLabels] = useState({});
    const [nextMonth, setNextMonth] = useState("");
    const [day, setDay] = useState("");
    const [nextReport, setNextReport] = useState("");
    const [updateLeaseInformation, setUpdateLeaseInformation] = useState(false);
    const [reconnect, setReconnect] = useState(false);
    useEffect(() => {
        dashboardRequest();
        // console.log(moment(moment().format("YYYYMM15"), "YYYYMMDD"))
        // console.log(moment(moment().format("YYYYMM15"), "YYYYMMDD").diff(moment(), 'days'))
        //
        // console.log(moment(moment().format("YYYYMM04"), "YYYYMMDD"))
        // console.log(moment(moment().format("YYYYMM04"), "YYYYMMDD").diff(moment(), 'days'))
        if(appState.plan.value !== 'basic'){
          if (moment(moment().format("YYYYMM04"), "YYYYMMDD").diff(moment(), 'days') < 0){
            setNextReport(moment().format('MMMM'));
          }else{
            setNextReport(moment().add(-1, 'month').format('MMMM'));
          }

        }else{
          setNextReport('June');
        }
    }, [])

    const dashboardRequest = () => {
        // if (!appState.profileUuid.value) {
        //     navigate('/');
        //     return;
        // }
        initAxiosInstance();
        const data = {
            profile_uuid: appState.profileUuid.value,
        }
        axiosInstance.post('/business/dashboard-1/', data)
            .then(function(response) {
                // console.log(response);
                // handle success

                if (response.data) {
                    mapDashboardDataToState(response.data);
                }
            })
            .catch(function(error, ) {
                console.log(error);
                // mapDashboardDataToState(dashboardData);
                // handle error
            })
    }

    const mapDashboardDataToState = (dashboardData) => {
        setBusinessName(dashboardData.business_name);
        setBusinessAddress(dashboardData.business_address);
        if('update_lease_information' in dashboardData){
          setUpdateLeaseInformation(dashboardData.update_lease_information);
        }
        if('reconnect' in dashboardData){
          setReconnect(dashboardData.reconnect);
        }
        if(!dashboardData.display_flag){
          if('dashboard_update_date' in dashboardData){
            setNextMonth(moment(dashboardData['dashboard_update_date']).format('MMMM'))
            setDay(dashboardData['dashboard_update_day'])
          }else{
            setNextMonth(moment().add(1, 'month').format('MMMM'));
            setDay('4th')
          }
          setLoading(false)
          return
        }
        setAsOfDate(dashboardData.score_data.as_of_date);
        setScore(dashboardData.score_data.score);
        setRating(dashboardData.score_data.rating);
        // setNextMonth(dashboardData.score_data.next_month)
        setChartLabel(`As of Now`) //${moment(asOfDate).format('MM/DD/YYYY')}`
        const coordinates = {
            past: [],
            future: []
        };
        const xLabels = [];
        const monthLabels_ = {}
        if (dashboardData.score_data && dashboardData.score_data.time_series) {
            Object.keys(dashboardData.score_data.time_series).map((key) => {
                const date = moment(key);
                const dateFormatted = moment(key).format('MMM YY')
                xLabels.push(dateFormatted);

                if (moment().diff(date, 'days') >= 0) {
                    coordinates.past.push({
                        x: dateFormatted,
                        y: dashboardData.score_data.time_series[key]
                    })
                    coordinates.future.push(null)
                    // if(moment(key).format('YY') === moment().format('YY') && moment().diff(date, 'days') >= 15) {
                    //     monthLabels_[moment(key).format('M')] = true
                    // }
                } else {
                    // Check if its the first date in future and if so get the last coordinate of past dates as a starting point
                    // if (coordinates.future[coordinates.future.length - 1] === null) {
                    //     coordinates.future[coordinates.future.length - 1] = coordinates.past[coordinates.past.length - 1];
                    // }
                    // coordinates.future.push({
                    //     x: dateFormatted,
                    //     y: dashboardData.score_data.time_series[key]
                    // })
                    coordinates.past.push(null)
                    coordinates.future.push(null)
                }

            })
        }

        if (dashboardData.score_data && dashboardData.score_data.report_series) {
            Object.keys(dashboardData.score_data.report_series).map((key) => {
                const date = moment(key);
                if (moment().diff(date, 'days') >= 0) {
                    if(moment(key).format('YY') === moment().format('YY') && moment().diff(date, 'days') >= 15 && dashboardData.score_data.report_series[key]) {
                        monthLabels_[moment(key).format('M')] = dashboardData.score_data.report_series[key]
                    }
                }
            })
        }

        setChartXLabels(xLabels);
        setChartCoordinates(coordinates);
        setMonthLabels(monthLabels_);
        setLoading(false)
    }

    const initAxiosInstance = () => {
        if (!axiosInstance) {
            axiosInstance = axios.create({
                baseURL: settings.get('GATSBY_DEBIE_API_BASE_URI'),
                timeout: 10000,
                // headers: {'X-Custom-Header': 'foobar'}
            });
        }
    }

    const upgrade = () => {
      appState.paymentPage.set(true)
      appState.loggedIn.set(false)
      navigate('/dashboard')
    }

    // console.log('MAIN', appState)

    return (
        <Layout showLoginButton={false} showLogoutButton={true}>
            {/* <p>{appState.test.value}</p> */}
            <main className="container">
                <div className="debieBusinessInfo">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="dashboardWrap">
                                <div className="heading">
                                    <h1>{businessName}</h1>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-md-8 col-lg-9">
                                        <div className="smHeading">
                                            <h2>{businessAddress}</h2>
                                        </div>
                                        { loading ? <div className="availableOffersList blankListPlaceholder">
                                            <img src="/images/blank-payment.png" alt="" />
                                          </div> : updateLeaseInformation ?
                                          (<div className="bankDetail padding10">
                                              <div className="bankMergingIcon">
                                                  <img src="/images/notification.svg" alt="" />
                                              </div>
                                              <div className="bankInfo">
                                                  <h3>
                                                          Finish Activating Reporting
                                                  </h3>
                                                  <button className="btn btn-xs" onClick={() => navigate('/account')}>Complete Lease Information</button>
                                              </div>
                                          </div>) : !reconnect ?
                                          (<div className="bankDetail">
                                            <div className="bankMergingIcon">
                                                <img src="/images/checked.png" alt="" />
                                            </div>
                                            <div className="bankMergingIcon ml-3">
                                                <img src="/images/stack-of-money.png" alt="" />
                                            </div>
                                            <div className="bankInfo">
                                                {/* <h3>Reporting Active</h3>*/}
                                                <span className="bankLogo">
                                                   {/* {
                                                        appState.institution.value && appState.institution.value.logo &&
                                                        <img src={`data:image/png;base64, ${appState.institution.value.logo}`} alt="" />
                                                    }
                                                    {
                                                        appState.institution.value && appState.institution.value.name &&
                                                        appState.institution.value.name
                                                    }  */}
                                                </span>
                                                <h3>
                                                    {/* <a href="#."> */}
                                                        Lease Reporting Active
                                                    {/* </a> */}
                                                </h3>
                                            </div>
                                          </div>) : (<div className="bankDetail padding10">
                                              <div className="bankMergingIcon">
                                                  <img src="/images/notification.svg" alt="" />
                                              </div>
                                              <div className="bankInfo">
                                                  <h3>
                                                          Your Account is Disconnected
                                                  </h3>
                                                  <button className="btn btn-xs" onClick={() => navigate('/auth')}>Reconnect Account</button>
                                              </div>
                                          </div>)

                                        }
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4 mb-4">
                                                <div className="xsHeading">
                                                    <h3>Debie Consolidated Score</h3>
                                                </div>
                                                <div className="boxPanel disabledBoxWithOverlay">
                                                    {
                                                        !score && !loading &&
                                                        <div className="disabledBoxCenter">
                                                            <div className="disabledBoxText">
                                                                <p>
                                                                { updateLeaseInformation ?
                                                                  `Your dashboard will update as `:
                                                                  `Your dashboard will update on`
                                                                }
                                                                <br/>
                                                                {updateLeaseInformation ? 'the information becomes available':`${nextMonth} ${day}`}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="dashboardMeter">
                                                        <div className="icon">
                                                          {
                                                            (() => {
                                                              if (isNaN(score) || score === "--" || score === null || score === undefined) {
                                                                return (
                                                                  <img src="/images/meter-1.png" alt="" />
                                                                )
                                                              } else {
                                                                if (score <= 370) {
                                                                  return (
                                                                    <img src="/images/meter-2.png" alt="" />
                                                                  )
                                                                } else if (score <= 564) {
                                                                  return (
                                                                    <img src="/images/meter-3.png" alt="" />
                                                                  )
                                                                } else if (score <= 752) {
                                                                  return (
                                                                    <img src="/images/meter-4.png" alt="" />
                                                                  )
                                                                } else {
                                                                  return (
                                                                    <img src="/images/meter-5.png" alt="" />
                                                                  )
                                                                }
                                                              }
                                                            })()
                                                          }
                                                        </div>
                                                        {/*<AnimatedMeter width={220} height={220} />
                                                         <img src="/images/meter.png" alt="" /> */}
                                                        <div className="meterText">
                                                            <h2>
                                                                { score ? score : '--' }
                                                            </h2>
                                                            <h3 style={{color: '#FFF'}}>
                                                              .  {/* rating ? rating : '--' */}
                                                            </h3>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            {false ? (<div className="col-sm-12 col-md-12 col-lg-6 col-xl-8">
                                                <div className="xsHeading">
                                                    <h3>Tips to improve your score</h3>
                                                </div>
                                                <div className="bankDetail smBankDetail">
                                                    <div className="bankMergingIcon">
                                                        <img src="/images/stack-of-money.png" alt="" />
                                                    </div>
                                                    <div className="bankInfo">
                                                        <h3>Increasing Working Capital</h3>
                                                        <p>Holding more cash on hand can improve your capitalization and your score</p>
                                                    </div>
                                                </div>
                                                <div className="bankDetail smBankDetail">
                                                    <div className="bankMergingIcon">
                                                        <img src="/images/time.png" alt="" />
                                                    </div>
                                                    <div className="bankInfo">
                                                        <h3>Ontime Payments</h3>
                                                        <p>Paying ontime is a hallmark of highly scored businesses</p>
                                                    </div>
                                                </div>
                                                <div className="bankDetail smBankDetail">
                                                    <div className="bankMergingIcon">
                                                        <img src="/images/merge.png" alt="" />
                                                    </div>
                                                    <div className="bankInfo">
                                                        <h3>Consolidating Accounts</h3>
                                                        <p>Consolidating your business accounts can improve your capitalization and your scores</p>
                                                    </div>
                                                </div>
                                            </div>) : (<div className="col-sm-12 col-md-12 col-lg-6 col-xl-8">
                                                <div className="xsHeading">
                                                    <h3>Reporting Details</h3>
                                                </div>
                                                <div className="reportingDetail">
                                                    <div className="reportingDetailRow">
                                                      <div className="bankMergingIcon">
                                                          <img src="/images/send.png" alt="" />
                                                      </div>
                                                      <div className="reportingInfo" >
                                                          <h3>Upcoming Report</h3>
                                                          {appState.plan.value === 'basic' && <button className="btn btn-xs" onClick = {() => upgrade()}>Upgrade to monthly</button>}
                                                      </div>
                                                    </div>
                                                    { loading ? <div className="reportingDetailRow text16">
                                                      <p className='leftPad'>
                                                          <img src="/images/blank-payment.png" alt="" />
                                                      </p>
                                                      </div> : <div className="reportingDetailRow text16">
                                                      <p className='leftPad'>
                                                        { updateLeaseInformation ?
                                                          'Reporting Not Active Please Update Lease Information' :
                                                          appState.plan.value !== "basic" ? 'You are reporting Monthly':'You are reporting Quarterly'
                                                        }
                                                      </p>
                                                      {!updateLeaseInformation && <p>Next Report: {nextReport}</p>}
                                                    </div>}
                                                    <div className="reportingDetailRow topMargin">
                                                      <div className="bankMergingIcon">
                                                          <img src="/images/time.png" alt="" />
                                                      </div>
                                                      <div className="reportingInfo">
                                                          <h3>Report History</h3>
                                                      </div>
                                                    </div>
                                                    { loading ? <div className="reportingDetailRow text16">
                                                      <p className='leftPad'>
                                                          <img src="/images/blank-payment.png" alt="" />
                                                      </p>
                                                      </div> : <div className="reportingDetailRow">
                                                      <div className="reportingYearEntry">
                                                        <p className='leftPad topPad'> 2021</p>
                                                      </div>
                                                      {['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'].map((item, index) => {
                                                        if ((index+1) in monthLabels){
                                                          return (<div className="reportingDetailColumn">
                                                            <p>{item}</p>
                                                            <img src="/images/tick.png" alt="" />
                                                          </div>)
                                                        } else {
                                                          return (<div className="reportingDetailColumn">
                                                            <p>{item}</p>
                                                            <img src="/images/cross.png" alt="" />
                                                          </div>)
                                                        }
                                                    })}
                                                    </div>}
                                                </div>
                                            </div>)}
                                            <div className="col-sm-12">
                                                <div className="boxPanel disabledBoxWithOverlay">
                                                    {
                                                        (!chartCoordinates || (chartCoordinates.past.length === 0 && chartCoordinates.future.length === 0)) &&
                                                        <div className="disabledBoxCenter">
                                                            <div className="disabledBoxText">
                                                            {!loading && <p>
                                                              { updateLeaseInformation ?
                                                                `Your dashboard will update as the information becomes available`:
                                                                `Your dashboard will update on ${nextMonth} ${day}`
                                                              }
                                                            </p>}
                                                            {loading ? <img src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif" /> : <img src="/images/waitingClock.svg" alt="" />}
                                                            </div>
                                                        </div>
                                                    }
                                                    <DashboardChart chartCoordinates={chartCoordinates} label={chartLabel} xLabels={chartXLabels} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="historyBox">
                                                    <h2>Offers based on your history</h2>
                                                    {
                                                        !appState.options &&
                                                        <>
                                                            <div>
                                                                <FundBox/>
                                                            </div>
                                                            {/*<div className="badge historyBadge">
                                                                <p>We are collecting options for you and will display them here when they are available.</p>
                                                            </div>

                                                            <div className="availableOffersList blankListPlaceholder">
                                                                <img src="/images/blank-payment.png" alt="" />
                                                            </div>
                                                            <div className="availableOffersList blankListPlaceholder">
                                                                <img src="/images/blank-payment.png" alt="" />
                                                            </div>
                                                            <div className="availableOffersList blankListPlaceholder">
                                                                <img src="/images/blank-payment.png" alt="" />
                                                            </div>*/}

                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            appState.options &&
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="invoiceBox">
                                                        <h3>Super Invoice Financing</h3>
                                                        <div className="financeLogo">
                                                            <p>logo</p>
                                                        </div>
                                                        <p>Quisque commodo mauris dignissim neque auctor, ac pharetra tellus eleifend. Cras vehicula tempor elit id mollis. Aenean posuere vulputate pharetra. </p>
                                                        <ul>
                                                            <li>
                                                                <span>Amount</span>
                                                                <span className="amount">
                                                                    Up to $50,000
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span>Terms</span>
                                                                <span className="amount">
                                                                    60, 90, 120 days
                                                                </span>
                                                            </li>
                                                        </ul>
                                                        <div className="text-center">
                                                            <a href="#." className="btn btn-default">Request a call</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="invoiceBox">
                                                        <h3>Super Invoice Financing</h3>
                                                        <div className="financeLogo">
                                                            <p>logo</p>
                                                        </div>
                                                        <p>Quisque commodo mauris dignissim neque auctor, ac pharetra tellus eleifend. Cras vehicula tempor elit id mollis. Aenean posuere vulputate pharetra. </p>
                                                        <ul>
                                                            <li>
                                                                <span>Amount</span>
                                                                <span className="amount">
                                                                    Up to $50,000
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span>Terms</span>
                                                                <span className="amount">
                                                                    60, 90, 120 days
                                                                </span>
                                                            </li>
                                                        </ul>
                                                        <div className="text-center">
                                                            <a href="#." className="btn btn-default">Request a call</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                    <div className="col-sm-12 col-md-4 col-lg-3">
                                        <div className="smHeading smScreen-space-lg">
                                            {/* <h2>DN Number <b>DN1092389</b></h2> */}
                                            <h3>Factors impacting your score</h3>
                                        </div>
                                        <div className="factorImpact">
                                            <div className="icon">
                                                <img src="/images/icon-001.png" alt="" />
                                            </div>
                                            <div className="factorIconText">
                                                <h4>Presence of legal suits or judgements on files</h4>
                                            </div>
                                        </div>
                                        <div className="factorImpact">
                                            <div className="icon">
                                                <img src="/images/icon-002.png" alt="" />
                                            </div>
                                            <div className="factorIconText">
                                                <h4>Non-financial trade past due balance history</h4>
                                            </div>
                                        </div>
                                        <div className="factorImpact">
                                            <div className="icon">
                                                <img src="/images/icon-003.png" alt="" style={{height:'30px'}}/>
                                            </div>
                                            <div className="factorIconText">
                                                <h4>Financial trade utilizations</h4>
                                            </div>
                                        </div>
                                        <div className="factorImpact">
                                            <div className="icon">
                                                <img src="/images/icon-004.png" alt="" style={{height:'30px'}}/>
                                            </div>
                                            <div className="factorIconText">
                                                <h4>Numbers of credit inquiries</h4>
                                            </div>
                                        </div>
                                        <div className="businessTips">
                                            <h3>Small Business Tips</h3>
                                            <div className="tipBox">
                                                <h4>Bills are important</h4>
                                                <p>Always pay your bills on time. Set up payment reminders to help you remember.</p>
                                            </div>
                                            <div className="tipBox">
                                                <h4>Use your options</h4>
                                                <p>Leverage credit options to improve your cash flow.</p>
                                            </div>
                                            <div className="tipBox">
                                                <h4>Stay on top of your score</h4>
                                                <p>Review your credit score on a regular basis to ensure you stay on top of things and take quick action if you need to make any changes.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    )

}
export default DashboardMainPage
