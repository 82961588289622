import React from 'react'
import Switch from 'react-switch'
class Toggle extends React.Component {
    // constructor(props) {
    //     super(props);
    //     this.state = { checked: false };
    //     this.handleChange = this.handleChange.bind(this);
    // }

    // handleChange(checked) {
    //     this.setState({ checked });
    //     console.log(checked)
    // }

    render() {
        return (
            <div style={{ textAlign: 'center', display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center' }}>
                <p className="textDecoration">Monthly</p>
                <label>
                <Switch onChange={this.props.setYearly} checked={this.props.yearly}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    className="textDecoration toggleButton"
                    height={18}    
                    width={45}
                    onColor={"#0000FF"}
                    borderRadius={100}
                    activeBoxShadow="0px 0px 1px 2px"
                    />
                </label>
                <p className="textDecoration">Yearly</p> 
            </div>

        );
    }
}
export default Toggle;

