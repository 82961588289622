import React, { useState, useContext, useEffect } from "react"
import Layout from "../components/layout"
import { AppStateContext } from "../contexts/AppStateContext";
import axios from 'axios'
import settings from "../lib/settings"
// import {loadStripe} from '@stripe/stripe-js';
import Loader from "../components/Loader"
// import { useSessionId } from '../hooks/useSessionId'
import './subscription.css'
import Toggle from "./toggle.js";


const product_ids = { 'bump': settings.get('GATSBY_STRIPE_BUMP_ID'), 'boost': settings.get('GATSBY_STRIPE_BOOST_ID'),'bump_yearly': settings.get('GATSBY_STRIPE_BUMP_ID_YEARLY'),'boost_yearly': settings.get('GATSBY_STRIPE_BOOST_ID_YEARLY')}
const prices = { 'bump': settings.get('GATSBY_STRIPE_BUMP_PRICE'), 'boost': settings.get('GATSBY_STRIPE_BOOST_PRICE'), 'bump_strike': settings.get('GATSBY_STRIPE_BUMP_PRICE_STRIKE'), 'boost_strike': settings.get('GATSBY_STRIPE_BOOST_PRICE_STRIKE'), 'boost_yearly': settings.get('GATSBY_STRIPE_BOOST_PRICE_YEARLY'), 'bump_yearly': settings.get('GATSBY_STRIPE_BUMP_PRICE_YEARLY')}
// const stripePromise = loadStripe('pk_test_XBdsfrGpBEn3unSBZtSO1ds100dKVWfzbM');

let axiosInstance = null;

const Subscription = () => {
    // let [data, loading] = useSessionId(window.location);
    const appState = useContext(AppStateContext);

    const [loading, setLoading] = useState(true);
    const [yearly, setYearly] = useState(true);
    const stripe = window.Stripe(settings.get('GATSBY_STRIPE_PUBLISHABLE_KEY'));

    const initAxiosInstance = () => {
      if (!axiosInstance) {
        axiosInstance = axios.create({
          baseURL: settings.get('GATSBY_DEBIE_API_BASE_URI'),
          timeout: 10000,
          // headers: {'X-Custom-Header': 'foobar'}
        });
      }
    }

    // const createCheckoutSession = (priceId) => {
    //   initAxiosInstance();
    //   const data = {
    //     priceId: priceId
    //   }
    //   axiosInstance.post('/create-checkout-session', data)
    //     .then(function (response) {
    //       // handle success
    //       console.log(response.json())
    //     })
    //     .catch(function (error) {
    //     })
    // }

    const chooseSubscription = function(plan) {
      const priceId = product_ids[plan]
      // console.log(priceId)
      createCheckoutSession(priceId, plan).then(function(data) {
        // Call Stripe.js method to redirect to the new Checkout page
        // localStorage.setItem('plan', plan)
        // localStorage.setItem('sessionId',data.sessionId)
        // localStorage.setItem('profileUuid',appState.profileUuid.value)
        appState.sessionId.set(data.sessionId)
        stripe.redirectToCheckout({
            sessionId: data.sessionId
          })
          .then(function(result){
            // console.log(JSON.stringify(result))
            // appState.paymentPage.set(false);
          });
      });
    }

    const chooseFree = () => {
      appState.plan.set('basic')
      // localStorage.setItem('plan', 'free')
      if (typeof window.dataLayer !== `undefined`) {
        window.dataLayer.push({'event': 'free'})
      }
      appState.paymentPage.set(false)
    }



    var createCheckoutSession = function(priceId, plan) {
    const baseUrl = settings.get('GATSBY_DEBIE_API_BASE_URI')
    return fetch(baseUrl + "/create-checkout-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          price_id: priceId,
          profile_uuid: appState.profileUuid.value,
          plan: plan,
          url: window.location.href
        })
      }).then(function(result) {
        // console.log(stripe)
        return result.json()
      });
    };

    const subscriptionSaveRequest = (flag) => {
      // console.log(window.location.href)
      initAxiosInstance();
      const data = {
        profile_uuid: appState.profileUuid.value,
        session_id: appState.sessionId.value,
        success: flag
      }
      axiosInstance.post('/save-subscription/', data)
        .then(function (response) {
          // handle success
          console.log('SUB', JSON.stringify(response), appState.plan.value)
          if('plan' in response.data ){
            if(flag && response.data.plan !== appState.plan.value){
              // console.log(window.dataLayer)
              if (typeof window.dataLayer !== `undefined`) {
                console.log(response.data.plan, appState.plan.value)
                window.dataLayer.push({'event': response.data.plan})
              }
              appState.plan.set(response.data.plan)
              appState.paymentPage.set(false);
            }
          }

          setLoading(false)

          // localStorage.setItem('profile_uuid', null)
          // localStorage.setItem('quickbooks', true);
          // window.close()
          // navigate('/dashboard');
          // Clear Errors
        })
        .catch(function (error) {
          console.log(error);
          // handle error
          if (error.response && error.response.status && error.response.status === 400) {
            // displayValidationErrors(error.response.data);
          }
        })
    }

    useEffect(() => {
      // console.log('STATE', appState.plan.value, appState.planUpgrade.value, appState.sessionId.value)

      if(appState.planUpgrade.value){
        if(appState.plan.value.includes('yearly')) {
          chooseSubscription('boost_yearly')
        }else {
          chooseSubscription('boost')
        }
        appState.planUpgrade.set(false)
      }else if(appState.sessionId.value !== ""){
        const flag = window.location.href.includes(appState.sessionId.value)
        if (flag){
          subscriptionSaveRequest(flag)
        }else {
          setLoading(false)
        }
      }else{
        setLoading(false)
      }
    }, [])

    // console.log('SUBSCRIPTION', appState)

    return (
        <Layout showLoginButton={false} showLogoutButton={true}>

        	<main className="container">
          <div className='container'>
            <div className="row">
            </div>
          </div>
            <div className="debieBusinessInfo">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="dashboardWrap">
                            <div className="heading">
                                {/* <h1>businessName</h1> */}
                            </div>
                            {loading ? <Loader/> :
                              <div className="section-4">
                                <div className="container-4 w-container">
                                <Toggle
                                  yearly={yearly}
                                  setYearly={setYearly}
                                />
                                  <div className="pricing-wrapper">
                                <div id="w-node-86fd7fe0db48-5541e0bb" className="plan-tile">
                                      <div className="plan-logo">
                                        <img
                                          src="https://uploads-ssl.webflow.com/5e1e8ecf24ceb8fd10ddfa00/5fcc2c3ed6c18c81030c86bb_Free%20logo.svg"
                                          loading="lazy"
                                          alt=""
                                        />
                                      </div>
                                      <div className="plan-text">
                                        <div className="plan-name">Basic</div>
                                        <div className="plan-price">
                                          <span className="text-span-3">$0</span>
                                          <span className="text-span-4">/month</span>
                                        </div>
                                      </div>
                                      <div id="w-node-6767efac843b-5541e0bb" className="pricing-divider"></div>
                                      <div id="w-node-abe0e5369764-5541e0bb" className="features-list-wrapper">
                                        <div className="checkmark">
                                          <img
                                            src="https://uploads-ssl.webflow.com/5e1e8ecf24ceb8fd10ddfa00/5fcc2ff8bba12c4ed4f85682_bi_check-circle-fill.svg"
                                            loading="lazy"
                                            alt=""
                                          />
                                        </div>
                                        <div className="features-text">
                                          <div className="text-block-3">
                                            Report <span className="text-span-5">4 Rent payments</span> per year
                                          </div>
                                        </div>
                                      </div>
                                      <div id="w-node-45e550786755-5541e0bb" className="features-list-wrapper">
                                        <div className="checkmark">
                                          <img
                                            src="https://uploads-ssl.webflow.com/5e1e8ecf24ceb8fd10ddfa00/5fcc2ff8bba12c4ed4f85682_bi_check-circle-fill.svg"
                                            loading="lazy"
                                            alt=""
                                          />
                                        </div>
                                        <div className="features-text">
                                          <div className="text-block-3">
                                            Report to <strong>
                                            Experian</strong> and <strong>
                                            LexisNexis</strong>
                                          </div>
                                        </div>
                                      </div>
                                      <a
                                        id="w-node-b4f19d7009e6-5541e0bb"
                                        onClick={()=>chooseFree()}
                                        className="btn nexttab btn-default btn-sub gtm-subscription-free"
                                        style={{color:'white', paddingTop: '15px'}}
                                        >Choose Plan</a>
                                    </div>
                                    <div id="w-node-55e74c466f18-5541e0bb" className="plan-tile featured">
                                      <div>
                                        <div className="plan-logo">
                                          <img
                                            src="https://uploads-ssl.webflow.com/5e1e8ecf24ceb8fd10ddfa00/5fcc2c3e153f7e1901dc5f27_Boost%20Logo.svg"
                                            loading="lazy"
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <div className="plan-text featured">
                                        <div className="plan-name">Boost</div>
                                        <div className="plan-strike-price">
                                          <span className="text-span-3">{prices['boost_strike']}</span>
                                          <span className="text-span-4">/month</span>

                                        </div>
                                        <div className="plan-price">
                                          <span className="text-span-3">{yearly ? prices['boost_yearly'] : prices['boost']}</span>

                                          <span className="text-span-4">/month</span>
                                            {yearly && <div className="con">
                                              <a href="#"className="buttonP">PAID YEARLY</a>
                                            </div>}
                                          </div>
                                      </div>
                                      <div id="w-node-3033eebd6175-5541e0bb" className="pricing-divider">
                                      </div>

                                      <div id="w-node-91643039d2c6-5541e0bb" className="features-list-wrapper">
                                        <div className="checkmark">
                                          <img
                                            src="https://uploads-ssl.webflow.com/5e1e8ecf24ceb8fd10ddfa00/5fcc2ff8bba12c4ed4f85682_bi_check-circle-fill.svg"
                                            loading="lazy"
                                            alt=""
                                          />
                                        </div>
                                        <div className="features-text">
                                          <div className="text-block-3 featured">
                                            Report <span className="text-span-5">12 Rent payments</span> per year
                                          </div>
                                        </div>
                                      </div>
                                      <div id="w-node-3485470b9b68-5541e0bb" className="features-list-wrapper">
                                        <div className="checkmark">
                                          <img
                                            src="https://uploads-ssl.webflow.com/5e1e8ecf24ceb8fd10ddfa00/5fcc2ff8bba12c4ed4f85682_bi_check-circle-fill.svg"
                                            loading="lazy"
                                            alt=""
                                          />
                                        </div>
                                        <div className="features-text">
                                          <div className="text-block-3 featured">
                                            Report to <strong>Experian</strong> and <strong>LexisNexis</strong>
                                          </div>
                                        </div>
                                      </div>
                                      <div id="w-node-b79d9af071fd-5541e0bb" className="features-list-wrapper">
                                        <div className="checkmark">
                                          <img
                                            src="https://uploads-ssl.webflow.com/5e1e8ecf24ceb8fd10ddfa00/5fcc2ff8bba12c4ed4f85682_bi_check-circle-fill.svg"
                                            loading="lazy"
                                            alt=""
                                          />
                                        </div>
                                        <div className="features-text">
                                          <div className="text-block-3 featured">
                                            Report to <strong>D&amp;B</strong> and <strong>Equifax</strong>
                                          </div>
                                        </div>
                                      </div>
                                      {yearly ? (<a
                                        id="w-node-a9e6d7456ad2-5541e0bb"
                                        onClick={()=>chooseSubscription('boost_yearly')}
                                        className="btn nexttab btn-default btn-sub gtm-subscription-boost-yearly"
                                        style={{color:'white', paddingTop: '15px'}}
                                        >Choose Plan</a>):(<a
                                          id="w-node-a9e6d7456ad2-5541e0bb"
                                          onClick={()=>chooseSubscription('boost')}
                                          className="btn nexttab btn-default btn-sub gtm-subscription-boost"
                                          style={{color:'white', paddingTop: '15px'}}
                                          >Choose Plan</a>)
                                      }
                                    </div>
                                    <div id="w-node-044b092507be-5541e0bb" className="plan-tile">
                                      <div className="plan-logo">
                                        <img
                                          src="https://uploads-ssl.webflow.com/5e1e8ecf24ceb8fd10ddfa00/5fcc2c3ec5248e3534290ca5_Bump%20logo.svg"
                                          loading="lazy"
                                          alt=""
                                        />
                                      </div>
                                      <div className="plan-text">
                                        <div className="plan-name">Bump</div>
                                        <div className="plan-strike-price">
                                          <span className="text-span-3">{prices['bump_strike']}</span>
                                          <span className="text-span-4">/month</span>
                                        </div>

                                        <div className="plan-price">
                                          <span className="text-span-3">{yearly ? prices['bump_yearly']: prices['bump']}</span>
                                          <span className="text-span-4">/month</span>
                                          {yearly && <div className="con">
                                            <a href="#"className="buttonP">PAID YEARLY</a>
                                            </div>}
                                        </div>
                                      </div>

                                      <div id="w-node-044b092507ca-5541e0bb" className="pricing-divider"></div>
                                      <div id="w-node-044b092507cb-5541e0bb" className="features-list-wrapper">
                                        <div className="checkmark">
                                          <img
                                            src="https://uploads-ssl.webflow.com/5e1e8ecf24ceb8fd10ddfa00/5fcc2ff8bba12c4ed4f85682_bi_check-circle-fill.svg"
                                            loading="lazy"
                                            alt=""
                                          />
                                        </div>
                                        <div className="features-text">
                                          <div className="text-block-3">
                                            Report <span className="text-span-5">12 Rent payments</span> per year
                                          </div>
                                        </div>
                                      </div>
                                      <div id="w-node-044b092507d4-5541e0bb" className="features-list-wrapper">
                                        <div className="checkmark">
                                          <img
                                            src="https://uploads-ssl.webflow.com/5e1e8ecf24ceb8fd10ddfa00/5fcc2ff8bba12c4ed4f85682_bi_check-circle-fill.svg"
                                            loading="lazy"
                                            alt=""
                                          />
                                        </div>
                                        <div className="features-text">
                                          <div className="text-block-3">
                                            Report
                                            to <strong>
                                            Experian</strong> and <strong>
                                            LexisNexis</strong>
                                          </div>
                                        </div>
                                      </div>
                                      {yearly ? (<a
                                        id="w-node-044b092507dd-5541e0bb"
                                        onClick={()=>chooseSubscription('bump_yearly')}
                                        className="btn nexttab btn-default btn-sub gtm-subscription-bump-yearly"
                                        style={{color:'white', paddingTop: '15px'}}
                                        >Choose Plan</a>):<a
                                          id="w-node-044b092507dd-5541e0bb"
                                          onClick={()=>chooseSubscription('bump')}
                                          className="btn nexttab btn-default btn-sub gtm-subscription-bump"
                                          style={{color:'white', paddingTop: '15px'}}
                                          >Choose Plan</a>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>

                            }
                        </div>
                    </div>
                </div>
            </div>
        </main>
        </Layout>
    )
}
export default Subscription
