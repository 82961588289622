import React from "react"
import { Router } from "@reach/router"
import DashboardPage from "../client_routed_pages/dashboard"
import ProfileUUIDSetter from "../client_routed_pages/ProfileUUIDSetter"


const DashboardRoute = () => {
    return (
        <Router basepath={`${__PATH_PREFIX__}/dashboard`}>
            <ProfileUUIDSetter path="/:uuid" />
            <DashboardPage path="/" />
        </Router>
    )
}

export default DashboardRoute