import React, { useContext, useEffect } from "react"
import { AppStateContext } from "../contexts/AppStateContext"
import { navigate } from "gatsby";

const ProfileUUIDSetter = ({uuid}) => {
    const appState = useContext(AppStateContext);
    
    useEffect(() => {
        appState.profileUuid.set(uuid);
        navigate('/dashboard');
    }, [appState.profileUuid,uuid])
   
    return (
        <></>
    )

}
export default ProfileUUIDSetter
