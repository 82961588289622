import React from 'react'
class FundBox extends React.Component {
    render() {
        return (
            <>
            <div className="mainFundBox">
                {/* <div style={{ width: '15rem' }}>
                    <div style={{textAlign:'center',background:'#FED463'}}>
                        <img className="imageStyle" src="/images/Group.png" />
                    </div>
                    <div style={{ marginTop: '15px' }}>
                        <h5>Fund your business with up to a $150k line of credit</h5>
                        <text>
                            We partnered with Fundbox to offer our customers access to revolving line of credit.
                            It’s quick to apply,
                            and credit decisions can take just minutes.
                            If approved, you can use your line of credit to take on new jobs or other opportunities to grow your business.
                        </text>
                        </div>
                        <div style={{marginTop:'20px'}}>
                            <a className="btn btn-primary" href="https://www.google.com/?client=safari">Learn more</a>
                        </div>
                    </div> */}

                    <div style={{width:'19rem'}}>
                        <div style={{textAlign:'center',background:'#F8BEAA', borderRadius: '5px', paddingTop: '3rem', paddingBottom: '3rem'}}>
                            <img className="imageStyle" src="/images/fundbox-logo.png" />
                        </div>
                        <div style={{marginTop:'15px'}}>
                            <h5 style={{fontWeight: '500'}}>Apply for up to $150k to fund and grow your business</h5>
                            <text style={{fontSize: '15px'}}>

                                Debie has partnered with Fundbox to provide businesses like yours with quick access to a revolving line of credit. You can get a decision in minutes and funds deposited into your account as soon as the next business day if approved.
                                Find out if you qualify, risk free.
                        </text>
                        </div>
                        <div style={{marginTop:'30px'}}>
                            <a className="btn btn-primary gtm-affiliate-fundbox" style={{paddingVertical: 20, paddingHorizontal: '10px', backgroundColor: '#3D9AE2'}} href="http://tracking.fundbox.com/aff_c?offer_id=14&aff_id=4076" target="_blank">Learn more</a>
                        </div>
                    </div>

                    {/* <div style={{width:'15rem'}}>
                        <div style={{ textAlign: 'center',background:'#ECE4D4'}}>
                            <img
                                className="imageStyle" src="/images/Group.png" />
                        </div>
                        <div style={{ marginTop: '15px' }}>
                            <h5>Apply for up to $150k to fund and grow your business </h5>
                            <text>
                                Fundbox gives business like yours access to credit in order help you reach your goals. They’ve removed the barriers surrounding the traditional credit process to offer you a streamlined way to fund your future. With their simple application, face decisions, and transparnet pricing, you can quickly find out if you’re approved for a revolving line of credit.
                        </text>
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <a className="btn btn-primary" href="https://www.google.com/?client=safari">Learn more</a>
                        </div>
                    </div> */}
                </div>
            </>

        );
    }
}
export default FundBox;
