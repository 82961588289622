import React, { useContext } from "react"
import { AppStateContext } from "../contexts/AppStateContext"
import DashboardMainPage from "./dashboard-main"
import Subscription from "./subscription"
import { navigate } from "gatsby"
import { LayoutLoader } from "../components/Loader"

const DashboardPage = () => {
    const appState = useContext(AppStateContext);
    const pUuid = "" //localStorage.getItem('profileUuid',"")
    const sId = "" //localStorage.getItem('sessionId',"")
    //
    // if( pUuid !== "" && appState.profileUuid.value === ""){
    //   appState.profileUuid.set(pUuid)
    // }
    // if( sId !== "" && appState.sessionId.value === ""){
    //   appState.sessionId.set(sId)
    // }

    // console.log('DASHBOARD', appState)

    // if (appState.accountSettings.value){
    //     reutrn <>
    // }else

    if(appState.profileUuid.value && appState.paymentPage.value){
        return <Subscription />
    }else if(appState.profileUuid.value ){
        return <DashboardMainPage />
    }else if(pUuid === "" && sId === "" && appState.profileUuid.value === "") {
        if (typeof window !== `undefined`) {
            navigate('/')
        }
        return null;
    } else {
      return <LayoutLoader/>
    }

}
export default DashboardPage
